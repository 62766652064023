export const USER_CHANNEL = 'Web';
export const INTERCEPTOR_SKIP_HEADER = 'X-Skip-Interceptor';
export const REFRESH_TOKEN_HEADER = 'Refresh-Token';
export const AUTHORIZATION_HEADER = 'Authorization';
export const API_KEY = 'Ocp-Apim-Subscription-Key';
export const DEFAULT_THUMBNAIL_EXTENSION = 'Thumbnail000001';

export enum STREAM_TYPES {
  POSTLIVE = 'PostLive',
  PERMANENT = 'Uploaded',
  SCHEDULED = 'Live',
  LIVESTREAMED = 'Saved'
}

export enum ENTITY_TYPES {
  PRODUCT = 'Product',
  EVENT = 'Event',
  STREAM = 'Stream'
}

export enum ERROR_CODES {
  FILE_TOO_LARGE = 'file-too-large'
}

export enum KEY_CODES {
  ENTER = 'Enter'
}

export enum TAG_TYPES {
  CUSTOMER_SOLUTION = 'CUSTOMER_SOLUTION',
  SELLER_SOLUTION = 'SELLER_SOLUTION',
  CUSTOMER_SELLER_SOLUTION = 'CUSTOMER_SELLER_SOLUTION',
  PRODUCT_ATTRIBUTE = 'PRODUCT_ATTRIBUTE',
  VIDEO_ATTRIBUTE = 'VIDEO_ATTRIBUTE'
}
export const INDEX_NOT_FOUND = -1;
export const MAX_ITEM_UPLOAD = 5;

export const DEFAULT_PAGE_SIZE = 10;

export const NO_OF_VIDEO_DEVICES = 3;
export const START_LIVE_STREAM_TIME = 15;
export const TIME_INTERVAL = 100;
export const DEFAULT_DEBOUNCE_TIME = 500;
export const PAGE_SIZE = 1000;

export enum DATE_FORMAT {
  DEFAULT_FORMAT = "yyyy-MM-dd'T'HH:mm:ssXXX",
  HOUR_MIN = 'hh:mm a',
  DATE = 'MMM d, yyyy',
  DATE_TIME_WITH_12_HOUR = 'dd-MM-yyyy, hh:mm a',
  DATE_FORMAT = 'MM-dd-yyyy, hh:mm a'
}

export enum StreamStatus {
  Schedule = 'SCHEDULED',
  Completed = 'COMPLETED',
  Canceled = 'CANCELED',
  Started = 'STARTED'
}

export enum VideoVisibility {
  Public = 'Public',
  Unlisted = 'Unlisted'
}

export enum CopyRightAction {
  PrivacyPolicy = 'Privacy',
  Terms = 'Terms',
  HelpSupport = 'HelpSupport'
}

export enum ColumnSizes {
  XS = 60,
  SM = 80,
  MD = 100,
  LG = 160,
  XL = 250
}

export const MID_NIGHT = '00';
export const NOON = '12';

export const MYSTERY_PRODUCT = 'mystery-product';

export const STONE_850_COLOR = '#2F2B27';
export const GRAY_550_COLOR = '#607266';
export const ANNOUNCEMENT_LENGTH = 150;
export const RESEND_OTP_TIMER = 90;

export const TOKEN_REFRESH_THRESHOLD = 120;

export enum CHAT_EVENTS {
  PRODUCT_UPDATED = 'Product updated! Check the next Product below',
  CHAT_DELETED = 'THIS MESSAGE HAS BEEN DELETED : ',
  DISABLE_USER_CHAT = 'THIS USER WHO SENT THE FOLLOWING MESSAGE HAS BEEN DISABLED : ',
  DISABLE_CHAT = 'CHAT INPUT HAS BEEN DISABLED BY HOST.',
  ENABLE_CHAT = 'CHAT INPUT HAS BEEN ENABLED BY HOST.'
}
export type ChatEventType = keyof typeof CHAT_EVENTS;
