import { useMutation, useQuery } from '@tanstack/react-query';
import { TFunction } from 'react-i18next';
import { API_ROUTES } from '../../constants/apiRoutes';

import { toast } from 'react-toastify';
import { FetchImagePayload, IAsset, Response } from '../../types';
import { IAssetPayloads } from '../../types/assetUploader';
import { RouteConfig } from '../../types/Route';
import { makeRequest } from '../../utils/axios';

export const useAssetUpload = (t: TFunction<string>, isToastMessageHidden?: boolean) => {
  const uploadFile = (payloads: IAssetPayloads) => {
    const formData = new FormData();
    formData.append('file', payloads.file);
    formData.append('entityId', payloads.entityId);
    formData.append('entityType', payloads.entityType);
    formData.append('isHeroShot', payloads.isHeroShot as unknown as string);

    const httpUploadConfig: RouteConfig = {
      path: API_ROUTES.IMAGE_UPLOAD,
      method: 'POST',
      data: formData
    };
    return makeRequest(httpUploadConfig);
  };
  const {
    mutate: imageUpload,
    isSuccess: isUploadSuccessful,
    isError: isUploadError,
    isLoading: isUploadingImage,
    data: uploadResponse
  } = useMutation(uploadFile, {
    onSuccess: () => {
      if (!isToastMessageHidden) {
        toast.success(t('imageUploadSuccess', { ns: 'httpMessage' }));
      }
    },
    onError: () => {
      if (!isToastMessageHidden) {
        toast.error(t('imageUploadError', { ns: 'httpMessage' }));
      }
    }
  });

  const deleteFile = (data: { assetId: number; entityId: number; entityType: string }) => {
    const formData = new FormData();
    formData.append('id', data.assetId.toString());
    const httpUploadConfig: RouteConfig = {
      path: `${API_ROUTES.IMAGE_UPLOAD}/${data.assetId}`,
      params: {
        entityId: data.entityId,
        entityType: data.entityType
      },
      method: 'DELETE',
      data: formData
    };
    return makeRequest(httpUploadConfig);
  };
  const {
    mutate: imageDelete,
    isSuccess: isDeleteSuccessful,
    isError: isDeleteError,
    isLoading: isDeletingImage
  } = useMutation(deleteFile, {
    onSuccess: () => {
      if (!isToastMessageHidden) {
        toast.success(t('imageDeleteSuccess', { ns: 'httpMessage' }));
      }
    },
    onError: () => {
      if (!isToastMessageHidden) {
        toast.error(t('imageDeleteError', { ns: 'httpMessage' }));
      }
    }
  });

  return {
    imageUpload,
    isUploadSuccessful,
    isUploadError,
    isUploadingImage,
    uploadResponse,
    imageDelete,
    isDeleteSuccessful,
    isDeleteError,
    isDeletingImage
  };
};

export const fetchImageInformation = async (
  entityDetails: FetchImagePayload
): Promise<Response<IAsset[]>> => {
  const routeConfig: RouteConfig = {
    method: 'GET',
    path: API_ROUTES.IMAGE_UPLOAD,
    params: {
      entityId: entityDetails.entityId,
      entityType: entityDetails.entityType
    }
  };
  return makeRequest<Response<IAsset[]>>(routeConfig);
};

export const useFetchImage = (entityDetails: FetchImagePayload) => {
  const {
    data: imageInformationData,
    error: imageInformationError,
    isLoading,
    isFetching,
    refetch
  } = useQuery(
    [`fetchImage_${entityDetails.entityType}_${entityDetails.entityId}`],
    () => fetchImageInformation(entityDetails),
    {
      select: (imageData) => {
        return imageData.data;
      },
      enabled: false,
      refetchOnWindowFocus: false
    }
  );

  return {
    imageInformationData,
    imageInformationError,
    isLoading,
    isFetching,
    refetch
  };
};
